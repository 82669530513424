import React, { useEffect } from "react";
import { useState, useRef } from 'react';
import { useSocket } from '../websocket/websocket';
import Modal from "./modal";
import '../assets/styles/modal.css'
import { useDarkMode } from '../context/DarkModeContext';


const DetailModal = ({isOpen, onClose, name, time, text, eventId, sessionId, driverId, timestamp, priority}) => {
    const { socket } = useSocket();
    const [newText, setNewText] = useState(text);
    const [audioSrc, setAudioSrc] = useState(null);
    const audioPlayerRef = useRef(null);
    const [prio, setPrio] = useState(0);
        const { isDarkMode, toggleDarkMode } = useDarkMode();
    

    const handleVerify = () => {
        socket.emit('radioListener', {
            data: {
                event: 'on_fix_transcript',
                operation: 'fixTranscript',
                params: {
                    event_id: eventId,
                    session_id: sessionId,
                    driver_id: driverId,
                    text: newText,
                    timestamp: timestamp,
                    priority: prio
                }
            }
        });

        onClose();
    };

    const togglePrio = () => {
        if(prio === 0){
            setPrio(1)
        }else{
            setPrio(0)
        }
    }

    useEffect(() => {
        setPrio(priority)
    }, [])

    useEffect(() => {
        if(isOpen){
            socket.emit('radioListener',{
                data: {
                    event: 'on_get_clip',
                    operation: 'getClip',
                    params:{
                        driver_id: driverId,
                        event_id: eventId,
                        session_id: sessionId,
                        timestamp: timestamp
                    }
                }
            })
            socket.on('on_get_clip', (message)=> {
                console.log(message)
                setAudioSrc(message.data.url)
            })
            return () => {
                socket.off('on_get_clip');
            };
        } else {
            // Pause the audio if the modal is closed
            const audioPlayer = document.getElementById('audioPlayer');
            if (audioPlayer) {
                audioPlayer.pause();
                audioPlayer.src = '';
                setAudioSrc('');
            }
        }    
    }, [isOpen])

    useEffect(() => {
        if (audioPlayerRef.current && audioSrc) {
            // Attach an event listener for the 'loadeddata' event
            const onLoadedData = () => {
                if (audioPlayerRef.current) {
                    audioPlayerRef.current.play().catch((error) => {
                        console.error('Error playing audio:', error);
                    });
                    console.log('Loaded and playing audioSrc:', audioSrc);
                }
            };

            audioPlayerRef.current.addEventListener('loadeddata', onLoadedData);

            // Update the src attribute
            audioPlayerRef.current.src = audioSrc;
            const audioPlayer = audioPlayerRef.current 

            // Clean up the event listener on component unmount or when audioSrc changes
            return () => {
                if (audioPlayer) {
                    audioPlayer.removeEventListener('loadeddata', onLoadedData);
                }            
            };
        }
    }, [audioSrc]);
    
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Text from {name} at {time}</h2>
            <textarea defaultValue={text} onChange={(e) => setNewText(e.target.value)} />
            <audio controls ref={audioPlayerRef}>
                <source src={audioSrc}></source>
            </audio>
            <div className="footer">    
                <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={prio}
                    onChange={togglePrio}
                />
                <label className={`prio-label ${isDarkMode ? 'text-white' : 'text-dark'}`}>Priority</label>                    
                <button className={`btn btn-dark ${isDarkMode ? 'border-light' : ''}`} onClick={handleVerify}>Save changes</button>
            </div>
        </Modal>
    );
};

export default DetailModal;