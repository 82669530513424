import React, { useEffect, useState, useRef, memo, useCallback, useId } from "react";
import ReactMarkdown from "react-markdown";
import '../assets/styles/summary-card.css';
import {
    PButtonPure,
    PButton,
    PDivider,
    PHeading,
    PModal,
    PTextFieldWrapper
} from '@porsche-design-system/components-react';
import { useDarkMode } from '../context/DarkModeContext';
import { useSocket } from '../websocket/websocket';

const SummaryCard = memo(({ title, eventId, sessionId, driverId, summaries, driver_transcriptions, showToast }) => {
    const contentArray = Array.isArray(summaries) ? summaries : ["no data"];
    const hasNoData = contentArray.includes('no data');
    const { socket } = useSocket();
    const cardRef = useRef(null);
    const { isDarkMode } = useDarkMode();    
    const [prompt, setPrompt] = useState("");
    const id = useId();
    const [isModalOpen, setIsModalOpen] = useState(false);

    // control the modal
    const onModalOpen = useCallback(() => {
        setIsModalOpen(true);
    }, []);
    const onModalDismiss = useCallback(() => {
        setPrompt("")
        setIsModalOpen(false);
    }, []);
    
    // push a message to the parent toast
    const showToastMessage = useCallback((message) => {
        showToast(message);
    }, []);

    // unix to hour - minute - second
    const formatTimestamp = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { hour: '2-digit', minute: '2-digit'};
        return date.toLocaleString('de', options);
    }, []);

    // unix to year - month - day
    const formatDate = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('de', options);
    }, []);

    const generateSummary = () => {
        onModalDismiss()
        if (driver_transcriptions) {
            console.log(`Generating new summary for ${title}`, prompt)
            showToastMessage({ text: `Generating new summary for ${title}`, state: 'info' });
            socket.emit('summarizeSessions', {
                session_id: sessionId,
                driver_id: driverId,
                transcription: driver_transcriptions
            }, prompt);
        } 
    };

    const deleteSummary = (timestamp) => {
        if(summaries) {
            console.log(`Deleting summary of ${title} (${sessionId})`)
            socket.emit('radioListener', {
                data: {
                    operation: "removeSummary",
                    event: "on_deleted_summary",
                    params: {
                        session_id: sessionId,
                        driver_id: driverId,
                        timestamp: timestamp
                    }
                }
            })
            showToastMessage({ text: `Deleted summary for ${title}`, state: 'info' });
        }
    };

    // renders summary labels
    const renderContent = useCallback(
        (items) => (
            <ul className="summary-list">
                {items.map((item, i) => (
                    <li key={i}>
                        <label className="summary-row">
                            <span className="summary-prompt">
                                <b>{item.prompt} </b>
                            </span>
                            <span>
                                ({formatDate(item.timestamp)} - {formatTimestamp(item.timestamp)})
                                <PButtonPure icon='delete' size="small" theme={`${isDarkMode ? 'dark' : 'light'}`} onClick={() => {deleteSummary(item.timestamp)}}/>
                            </span>
                            <ReactMarkdown>{item.text}</ReactMarkdown>
                            <PDivider theme="auto"/>
                        </label>
                    </li>
                ))}
            </ul>
        ),
        [formatTimestamp, formatDate, deleteSummary, sessionId]
    );

    // Get the date from the first content item
    const date = contentArray.length > 0 && contentArray[0].timestamp ? formatDate(contentArray[0].timestamp) : "";

    const array_length = (driver_transcriptions || []).length
    
    return (
        <div className={`card 
            ${isDarkMode ? 'text-white bg-dark' : ''}`}>
            <div className={`card-header ${isDarkMode ? 'border-light' : ''}`}>
                <div>
                    <h5 size="medium">
                        {title} - <span style={{fontWeight:400}}>{date}</span>
                    </h5>
                    <label className="transcriptcount">{array_length} transcript(s) available</label>
                </div>
            </div>

            <div className="card-body" ref={cardRef}>
                {hasNoData ? (
                    <div>No data</div>
                ) : (
                    <div>{renderContent(contentArray)}</div>
                )}
            </div>
            <div className={`card-footer ${isDarkMode ? 'border-light' : ''}`}>
                <PButtonPure 
                    icon="plus" 
                    onClick={onModalOpen} 
                    theme={`${isDarkMode ? 'dark' : 'light'}`} 
                    disabled={(driver_transcriptions) ? false : true}
                >
                    New Summary 
                </PButtonPure>
            </div>
            <PModal 
                theme="auto" 
                open={isModalOpen} 
                onDismiss={onModalDismiss} 
                backdrop="shading"
                onKeyDown={e => {
                    if (e.key === "Enter") 
                        generateSummary();
                    }
                }
            >
                <PHeading theme="auto" slot="header" size="large" tag="h2">
                    Generate new summary for {driverId}
                </PHeading>
                <PTextFieldWrapper 
                    theme="auto" 
                    label="Prompt" 
                    description="Customize the summary by specifying a prompt."
                >
                    <input 
                        id={id} 
                        value={prompt} 
                        onInput={e => setPrompt(e.target.value)}
                        type="text" 
                        placeholder="(optional)"
                    />
                </PTextFieldWrapper>
                <PButton theme="auto" slot="footer" onClick={generateSummary}>
                    Generate
                </PButton>
            </PModal>
        </div>
    )
});

export default SummaryCard;