import React, { useCallback, useState, useEffect } from 'react';
import { useSocket } from '../websocket/websocket';
import Card from '../components/card';
import '../assets/styles/audio.css';
import '../assets/styles/custom.css';
import { useEvent } from '../websocket/eventContext';
import { useDarkMode } from '../context/DarkModeContext';

export default function Audio() {
    const { socket, eventList, streamUrls } = useSocket();
    const { selectedEventId } = useEvent();
    const [events, setEvents] = useState([]);
    const [streams, setStreams] = useState([]);
    const [filteredStreams, setFilteredStreams] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [streamurls, setStreamUrls] = useState([]);
    const [selectedSessionId, setSelectedSessionId] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [transcriptsShort, setTranscriptsShort] = useState(null);
    const [streamStatuses, setStreamStatuses] = useState(new Set());
    const { isDarkMode } = useDarkMode();
    const [query, setQuery] = useState('');

    useEffect(() => {
        socket.on('fetchSessionsResponse', (message) => {
            console.log('Sessions', message);
            const sessions = message.sessions.map(session => ({
                id: session.id,
                name: session.name
            }));
            setSessions(sessions);
        });

        // get all transcripts for selected event and session
        socket.on('on_get_all_transcripts', (message) => {
            console.log('transcripts: ', message);
            const transformedTranscripts = Object.keys(message.data).reduce((acc, driverId) => {
                acc[driverId] = message.data[driverId].transcription;
                return acc;
            }, {});
            setTranscriptsShort(transformedTranscripts);
        });

        // response when there is a new transcription
        socket.on('on_get_new_transcription', (message) => {
            if (!message.msg.Complete) {
                return;
            }
            console.log('new transcription', message);
            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: [
                            ...prevTranscriptsShort[message.driver_id],
                            message.msg
                        ]
                    };
                } else {
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id] : [message.msg]
                    }
                }
            });
        });

        socket.on('on_get_updated_transcription', (message) => {
            console.log('updated transcription', message);

            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    const updatedTranscripts = prevTranscriptsShort[message.driver_id].map((transcription) =>
                        transcription.RecognitionTime === message.msg.RecognitionTime ? message.msg : transcription
                    );
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: updatedTranscripts
                    };
                } else {
                    console.error(`Driver with ID ${message.driver_id} not found.`);
                    return prevTranscriptsShort;
                }
            });
        });

        // get active streams
        socket.on('on_get_streams_streams', (message) => {
            console.log(message);
            const newStreamStatuses = new Set(streamStatuses);

            Object.keys(message).forEach((streamName) => {
                newStreamStatuses.add(streamName);
            });

            setStreamStatuses(newStreamStatuses);
        });

        return () => {
            socket.off('on_get_all_transcripts');
            socket.off('on_get_new_transcription');
            socket.off('on_get_streams_streams');
            socket.off('on_get_updated_transcription');
        };
    }, []);

    const handleSelectAll = useCallback(() => {
        setSelectedValues(streams.map(stream => stream.streamName));
    }, [streams]);

    const handleSelect = useCallback((streamName) => {
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(streamName)) {
                // If already selected, remove it
                return prevSelectedValues.filter((name) => name !== streamName);
            } else {
                // Otherwise, add it
                return [...prevSelectedValues, streamName];
            }
        });
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelectedValues([]);
    }, []);

    // get event list
    useEffect(() => {
        if (eventList) {
            setEvents(eventList);
        }
    }, [eventList]);

    // for session ids
    useEffect(() => {
        if (selectedEventId){
            console.log(selectedEventId);
        }

        socket.emit('fetchSessions', selectedEventId);
    }, [selectedEventId, socket]);
    
    // map streaUrls to stream array
    useEffect(() => {
        if (streamUrls) {
            const streamUrlsData = streamUrls.data;
            const streamNameToUrlMap = Object.keys(streamUrlsData).reduce((acc, key) => {
                acc[key] = streamUrlsData[key];
                return acc;
            }, {});
            setStreamUrls(streamNameToUrlMap);
        }
    }, [streamUrls])
   
    // for stream names (driver)
    useEffect(() => {
        socket.emit('radioListener', {
            data: {
                event: 'on_get_drivers',
                operation: 'getDrivers'
            }
        })  
        // get streams(drivers)
        socket.on('on_get_drivers', (message) => {
            console.log('got drivers:', message);
            const streams = message.data.map((driver, index) => ({
                index,
                streamId: driver.driverId,
                streamName: driver.driverNameShort,
                // url: streamurls[driver.driverNameShort]
            }));
            setStreams(streams);
        });

        return () => {
            socket.off('on_get_drivers');
        };
    }, [socket, streamurls]);

    // for transcripts and active streams per session
    useEffect(() => {
        if(selectedSessionId){
            console.log('fetching for:', selectedSessionId)
            // transform stream array for request
            const transformedStreams = streams.map(stream => stream.streamName)
            socket.emit('radioListener', {
                data: {
                    event: 'on_get_all_transcripts',
                    operation: 'getAllTranscripts',
                    params: {
                        driver_ids: transformedStreams,
                        event_id: selectedEventId,
                        session_id: selectedSessionId,
                    }
                }
            });

            socket.emit('radioListener', {
                data: {
                    event: 'on_get_streams_streams',
                    operation: 'getStreams',
                    params: {
                        session_id: selectedSessionId
                    }
                }
            });
        }
    }, [selectedSessionId, socket]);

    useEffect(() => {
        if (streams) {
            const filtered = streams.filter(stream => selectedValues.includes(stream.streamName));
            if (query) {
                filtered.forEach(stream => {
                    stream.filteredContent = (transcriptsShort[stream.streamName] || []).filter(transcription =>
                        transcription.Text.toLowerCase().includes(query.toLowerCase())
                    );
                });
            } else {
                filtered.forEach(stream => {
                    stream.filteredContent = transcriptsShort[stream.streamName] || [];
                });
            }
            setFilteredStreams(filtered);
        }
    }, [selectedValues, query, transcriptsShort]);

    useEffect(() => {
        if (!selectedSessionId) return;

        if (selectedSessionId) {
            socket.emit('radioListener', {
                data: {
                    operation: 'startSessionSubs',
                    params: {
                        session_id: selectedSessionId
                    }
                }
            })
            // Cleanup function to stop the subscription
            return () => {
                socket.emit('radioListener', {
                    data: {
                        operation: 'stopSessionSubs',
                        params: {
                            session_id: selectedSessionId
                        }
                    }
                });
            };
        }
    }, [selectedSessionId, socket]);

    const selectedEventName = events.find((event) => event.id === selectedEventId)?.name;

    return (
        <div className="content">
            <div className='btn-toolbar justify-content-between' role='toolbar'>
                <div className="btn-group" role='group'>
                    {selectedEventName && <h4 className={`event-name-title ${isDarkMode ? 'text-white' : ''}`}><b>{selectedEventName}-</b></h4>}
                    <select
                        id="session"
                        className={`form-select form-select-sm ${isDarkMode ? 'text-white bg-dark border-dark' : ''}`}
                        aria-label="Default select example"
                        value={selectedSessionId}
                        onChange={(e) => setSelectedSessionId(e.target.value)}
                    >
                        <option>Session</option>
                        {sessions.map((session) => (
                            <option key={session.id} value={session.id}>
                                {session.name}
                            </option>
                        ))}
                    </select>
                    {selectedSessionId && (
                        <div className='dropdown'>
                            <button
                                className={`btn ${isDarkMode ? 'btn-dark text-white' : 'btn-light'} dropdown-toggle`}
                                type="button"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="outside"
                                aria-expanded="false">
                                Select Streams
                            </button>
                            <ul className={`dropdown-menu ${isDarkMode ? 'text-white bg-dark border-light' : ''}`}>
                                <li className={`dropdown-item ${isDarkMode ? 'text-white' : ''}`}>
                                    <button onClick={handleSelectAll}>
                                        Select All
                                    </button>
                                    <button onClick={handleDeselectAll}>
                                        X
                                    </button>
                                </li>
                                <li><hr className="dropdown-divider bg-light" /></li>
                                {streams.map((stream) => (
                                    <li key={stream.index} className={`dropdown-item ${isDarkMode ? 'text-white' : ''}`}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                id={`checkbox-${stream.streamId}`}
                                                checked={selectedValues.includes(stream.streamName)}
                                                onChange={() => handleSelect(stream.streamName)}
                                            />
                                            {stream.streamName}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                    <div className="input-group mr-10">
                        <input
                            type="text"
                            className={`form-control ${isDarkMode ? 'text-white bg-dark border-dark' : ''}`}
                            placeholder="Search transcriptions..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </div>
            </div>
            <div className='container-fluid mt-3'>
                <div className='row'>
                    {filteredStreams.map((stream) => {
                        const isLive = streamStatuses.has(stream.streamName);
                        return (
                            <div className='col-12 col-sm-6 col-xl-4 col-xxl-3 col-xxxl-2 col-xxxxl-1 mx-0 mb-2 px-1' key={stream.index}>
                                <Card
                                    title={stream.streamName}
                                    url={`https://prs.dev.public.pmsp.biz/storage/files/${selectedSessionId}/${stream.streamName}/out.m3u8`}
                                    eventId={selectedEventId}
                                    sessionId={selectedSessionId}
                                    driverId={stream.streamName}
                                    isLive={isLive}
                                    content={stream.filteredContent.map(transcription => ({
                                        time: transcription.RecognitionTime,
                                        text: transcription.Text,
                                        timestamp: transcription.RecognitionTime,
                                        priority: transcription.priority
                                    }))}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}