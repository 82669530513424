import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [streamUrls, setStreamUrls] = useState(null);
    const [streamPlan, setStreamPlan] = useState(null);
    const [prioWords, setPrioWords] = useState(null);
    const [eventList, setEventList] = useState(null)
    
    useEffect(() => {
        console.log('SocketProvider mounted');

        const initializeSocket = async () => {
            try {
                // Perform authentication and set the cookie
                const response = await axios.get('https://prs.dev.public.pmsp.biz/auth/api/user/authenticate', { withCredentials: true });
                if (response.data.success){
                    console.log('successful')
                } else {
                    window.location.href = 'https://prs.dev.public.pmsp.biz/auth/api/authenticate/login'
                }

                // Initialize the socket connection
                const socket = io('https://prs.dev.public.pmsp.biz', { 
                    path: "/api/socket.io/",
                    transport: ["websocket"],
                    withCredentials: true,
                });

                // Set up event handlers
                socket.on('connect', () => {
                    console.log('Socket.IO connected');
                    setIsConnected(true);
                    
                    // get event ids
                    socket.emit('fetchRaceEvents');

                    // request StreamUrls
                    socket.emit('radioListener', {
                        data: {
                            event: 'on_get_streamurls',
                            operation: 'getStreamurls',
                            params: {},
                        } 
                    });

                    // request Streamplan
                    socket.emit('radioListener', {
                        data: {
                            event: 'on_get_streamplan',
                            operation: 'getStreamplan',
                            params: {}
                        }
                    });

                    // request Priowords
                    socket.emit('radioListener', {
                        data : {
                            event: 'on_get_priowords',
                            operation: 'getPriowords',
                            params: {}
                        }
                    });
                });

                socket.on('connect_error', (err) => {
                    console.log(err.message);
                    console.log(err.description);
                    console.log(err.context);
                });

                socket.on('disconnect', () => {
                    console.log('Socket.IO disconnected');
                    setIsConnected(false);
                });

                socket.on('error', (error) => {
                    console.error('Socket.IO error:', error);
                });

                socket.on('fetchRaceEventsResponse', (message) => {
                    console.log('received eventlist:', message)
                    setEventList(message)
                });

                // get StreamUrls
                socket.on('on_get_streamurls', (message) => {
                    console.log('Received StreamUrls:', message);
                    setStreamUrls(message); 
                });

                // get Streamplan
                socket.on('on_get_streamplan', (message => {
                    console.log('received streamplan', message);
                    setStreamPlan(message);
                }))

                // get Priowords
                socket.on('on_get_priowords', (message => {
                    console.log('received priowords', message);
                    setPrioWords(message)
                }))

                socket.on('on_get_stream_update', (message) => {
                    console.log(message)
                })

                setSocket(socket);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        initializeSocket();

    }, []);
    
    return (
        <SocketContext.Provider value={{ socket, isConnected, streamUrls, streamPlan, prioWords, eventList }}>
            {!loading ? children : <div>Initializing socket...</div>}
        </SocketContext.Provider>
    );
};
