import React, {useCallback, useState, useEffect } from 'react';
import { useSocket } from '../websocket/websocket';
import SummaryCard from '../components/summary-card';
import '../assets/styles/session-summaries.css';
import '../assets/styles/custom.css';
import { useEvent } from '../websocket/eventContext';
import { useDarkMode } from '../context/DarkModeContext';
import {
    PToast,
    useToastManager
} from '@porsche-design-system/components-react';

export default function SessionSummaries(){
    const {socket, eventList, streamUrls} = useSocket();
    const { selectedEventId } = useEvent();
    const [events, setEvents] = useState([]);
    const [streams, setStreams] = useState([]);
    const [filteredStreams, setFilteredStreams] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [streamurls, setStreamUrls] = useState([]);
    const [selectedSessionId, setSelectedSessionId] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [transcriptsShort, setTranscriptsShort] = useState(null)
    const [streamStatuses, setStreamStatuses] = useState(new Set());
    const { isDarkMode } = useDarkMode();
    // const [selectedModel, setSelectedModel] = useState([]);
    const [summaries, setSummaries] = useState({});
    const { addMessage } = useToastManager();
    
    useEffect(() => {
        socket.on('fetchSessionsResponse', (message) => {
            console.log('Sessions', message)
            const sessions = message.sessions.map(session => ({
                id: session.id,
                name: session.name
            }));
            setSessions(sessions)
        })

        // get all transcripts for selected event and session
        socket.on('on_get_all_transcripts', (message) => {
            console.log('transcripts: ', message)
            const transformedTranscripts = Object.keys(message.data).reduce((acc, driverId) => {
                acc[driverId] = message.data[driverId].transcription;
                return acc;
            }, {});
            setTranscriptsShort(transformedTranscripts);
        })

        // response when there is a new transcription
        socket.on('on_get_new_transcription', (message) => {
            if (!message.msg.Complete) {
                return
            }
            console.log('new transcription', message);
            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: [
                            ...prevTranscriptsShort[message.driver_id],
                            message.msg
                        ]
                    };
                } else {
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id] : [message.msg]
                    }
                }
            });
        });

        socket.on('on_get_updated_transcription', (message) => {
            console.log('updated transcription',message)
            
            setTranscriptsShort((prevTranscriptsShort) => {
                if (prevTranscriptsShort[message.driver_id]) {
                    const updatedTranscripts = prevTranscriptsShort[message.driver_id].map((transcription) =>
                        transcription.RecognitionTime === message.msg.RecognitionTime ? message.msg : transcription
                    );
                    return {
                        ...prevTranscriptsShort,
                        [message.driver_id]: updatedTranscripts
                    };
                } else {
                    console.error(`Driver with ID ${message.driver_id} not found.`);
                    return prevTranscriptsShort;
                }
            });
        });

        

        /* 
        on_get_summaries
        {
            "type": "summary"
            "driver_id": "WCAR5",
            "session_id": "d4c01727-d9b5-499c-820a-43a6054ba856",
            "summary": [
                {
                    "Prompt": "This is a test prompt.",
                    "Text": "This is an updated test summary.",
                    "Timestamp": "1740558406.000000",
                    "Updated": "1741618518.815576"
                }
            ],
        }
        */
        socket.on('on_get_summaries', (message) => {
            console.log("summaries:", message)
            const transformedSummaries = Object.keys(message.data).reduce((acc, driverId) => {
                acc[driverId] = message.data[driverId].summary;
                return acc;
            }, {});
            setSummaries(transformedSummaries)
        });
        
        /* 
        on_get_new_summary
        {
            "driver_id": "WCAR6",
            "msg": {
                "Timestamp": "1740558406.000001",
                "Text": "2",
                "Prompt": "1"
            }
        } */
        socket.on('on_get_new_summary', (message) => {
            console.log("new summary:", message);
            const { driver_id, msg } = message;
            setSummaries((prevSummaries) => {
                if (prevSummaries[message.driver_id]) {
                    return {
                        ...prevSummaries,
                        [driver_id]: [
                            ...(prevSummaries[driver_id]),
                            msg
                        ]
                    };
                } else {
                    return {
                        ...prevSummaries,
                        [driver_id]: [msg]
                    };
                }
            });
            addMessage({ text: `New summary for ${driver_id}`, state: 'success' });
        });
        /*
        on_get_updated_summary
        {
            "driver_id": "WCAR5",
            "session_id": "d4c01727-d9b5-499c-820a-43a6054ba856",
            "msg": [
                {
                    "Prompt": "This is a test prompt.",
                    "Text": "This is an updated test summary.",
                    "Timestamp": "1740558406.000000",
                    "Updated": "1741618518.815576"
                }
            ]
        }
        */
        socket.on('on_get_updated_summary', (message) => {
            console.log("updated summary:", message)
            const { driver_id, msg } = message;
            setSummaries((prevSummaries) => {
                if (prevSummaries[driver_id]) {
                    const updatedSummaries = prevSummaries[driver_id].map(
                        (summary) => summary.Timestamp === msg.Timestamp ? msg : summary
                    );
                    addMessage({ text: `Updated summary for ${driver_id}`, state: 'success' });
                    return {
                        ...prevSummaries,
                        [driver_id]: updatedSummaries
                    };
                } else {
                    console.error(`Error during message handling for ${driver_id} updated summary.`);
                    return prevSummaries;
                }
            });
        });

        socket.on('on_deleted_summary', (message) => {
            console.log("summaries after deletion:", message);
        
            const transformedSummary = Object.keys(message.data).reduce((acc, driverId) => {
                acc[driverId] = message.data[driverId].summary;
                return acc;
            }, {});
        
            setSummaries((prevSummaries) => {
                const updatedSummaries = { ...prevSummaries };
        
                Object.keys(transformedSummary).forEach((driverId) => {
                    if (updatedSummaries[driverId]) {
                        updatedSummaries[driverId] = transformedSummary[driverId];
                    }
                });
        
                return updatedSummaries;
            });
        });

        // get active streams
        socket.on('on_get_streams_streams', (message) => {
            console.log(message)
            const newStreamStatuses = new Set(streamStatuses);

            Object.keys(message).forEach((streamName) => {
                newStreamStatuses.add(streamName);
            });

            setStreamStatuses(newStreamStatuses);
        });

        return () => {
            socket.off('on_get_all_transcripts');
            socket.off('on_get_summaries');
            socket.off('on_get_new_transcription');
            socket.off('on_get_new_summary')
            socket.off('on_get_streams_streams');
            socket.off('on_get_updated_transcription')
            socket.off('on_get_updated_summary')
        };
    }, [socket, streamStatuses])

    const handleSelectAll = useCallback(() => {
        setSelectedValues(streams.map(stream => stream.streamName));
    }, [streams]);

    const handleSelect = useCallback((streamName) => {
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(streamName)) {
                // If already selected, remove it
                return prevSelectedValues.filter((name) => name !== streamName);
            } else {
                // Otherwise, add it
                return [...prevSelectedValues, streamName];
            }
        });
    }, []);

    const handleDeselectAll = useCallback(() => {
        setSelectedValues([]);
    }, []);

    // get event list
    useEffect(() => {
        if (eventList) {
          setEvents(eventList);
        }
      }, [eventList]);

    // for session ids
    useEffect(() => {
        if (selectedEventId){
            console.log(selectedEventId)
        }

        socket.emit('fetchSessions', selectedEventId)

    }, [selectedEventId, socket]);
    
    // map streaUrls to stream array
    useEffect(() => {
        if(streamUrls){
            const streamUrlsData = streamUrls.data
            const streamNameToUrlMap = Object.keys(streamUrlsData).reduce((acc, key) => {
                acc[key] = streamUrlsData[key];
                return acc;
            }, {});
            setStreamUrls(streamNameToUrlMap)
        }
    }, [streamUrls])
   
    // for stream names (driver)
    useEffect(() => {
        socket.emit('radioListener', {
            data: {
                event: 'on_get_drivers',
                operation: 'getDrivers'
            }
        })  
        // get streams(drivers)
        socket.on('on_get_drivers', (message) => {
            console.log('got drivers:',message)
            const streams = message.data.map((driver, index) => ({
                index,
                streamId: driver.driverId,
                streamName: driver.driverNameShort,
                url: streamurls[driver.driverNameShort]
            }))
            setStreams(streams)
        })

        return () => {
            socket.off('on_get_drivers')
        }
    }, [socket, streamurls]);

    // for transcripts, summaries and active streams per session
    useEffect(() => {
        if(selectedSessionId){
            console.log('fetching for:', selectedSessionId)
            // transform stream array for request
            const transformedStreams = streams.map(stream => stream.streamName)
            socket.emit('radioListener', {
                data: {
                    event: 'on_get_all_transcripts',
                    operation: 'getAllTranscripts',
                    params: {
                        driver_ids: transformedStreams,
                        event_id: selectedEventId, 
                        session_id: selectedSessionId,
                    }
                }
            })
            socket.emit('radioListener', {
                data: {
                    event: 'on_get_streams_streams',
                    operation: 'getStreams',
                    params: {
                        session_id: selectedSessionId
                    }
                }
                    
            }) 
            socket.emit('radioListener', {
                data: {
                    event: 'on_get_summaries',
                    operation: 'getSummaries',
                    params: {
                        driver_ids: transformedStreams,
                        event_id: selectedEventId, // not really necessary
                        session_id: selectedSessionId
                    }
                }
            })
        }
    }, [selectedSessionId, socket])

    useEffect(() => {
        if (!selectedSessionId) return;

        console.log(`Starting session subs for session id: ${selectedSessionId}`)
        if(selectedSessionId){
            socket.emit('radioListener', {
                data: {
                    operation: 'startSessionSubs',
                    params: {
                        session_id: selectedSessionId
                    }
                }
            })
            // Cleanup function to stop the subscription
            return () => {
                socket.emit('radioListener', {
                    data: {
                        operation: 'stopSessionSubs',
                        params: {
                            session_id: selectedSessionId
                        }
                    }
                });
            };
        }
    }, [selectedSessionId, socket])

    useEffect(() => {
        if (streams) {
            const filtered = streams.filter(stream => selectedValues.includes(stream.streamName));
            filtered.forEach(stream => {
                stream.filteredContent = (summaries[stream.streamName] || [])
            });
            setFilteredStreams(filtered);
        }
    }, [selectedValues, summaries, streams]);
    

    const selectedEventName = events.find((event) => event.id === selectedEventId)?.name;
    
    // add select for ai model (4o/4o-mini) for dev

    return(
        <div className="content">
            <div className="btn-group" role='group'>
                {selectedEventName && <h4 className={`event-name-title ${isDarkMode ? 'text-white' : ''}`}><b>{selectedEventName}-</b></h4>}
                <select
                    id="session"
                    className={`form-select form-select-sm ${isDarkMode ? 'text-white bg-dark border-dark' : ''}`} 
                    aria-label="Default select example"
                    value={selectedSessionId}
                    onChange={(e) => setSelectedSessionId(e.target.value)}
                    >
                
                <option>Session</option>
                    {sessions.map((session) => (
                        <option key={session.id} value={session.id}>
                        {session.name}
                        </option>
                    ))}
                </select>
                {selectedSessionId && (
                    <div className='dropdown'> 
                        <button 
                            className={`btn ${isDarkMode ? 'btn-dark text-white' : 'btn-light'} dropdown-toggle`} 
                            type="button" 
                            data-bs-toggle="dropdown" 
                            data-bs-auto-close="outside"
                            aria-expanded="false">
                            Select Streams
                        </button> 
                        <ul className={`dropdown-menu ${isDarkMode ? 'text-white bg-dark border-light' : ''}`}>
                            <li className={`dropdown-item ${isDarkMode ? 'text-white' : ''}`}>
                                <button onClick={handleSelectAll}>
                                    Select All
                                </button>
                                <button onClick={handleDeselectAll}>
                                    X
                                </button>
                            </li>
                                                        
                            <li><hr className="dropdown-divider bg-light"/></li>
                            {streams.map((stream) => (
                                <li key={stream.index} className={`dropdown-item ${isDarkMode ? 'text-white' : ''}`}>
                                    <label>
                                        <input 
                                            type="checkbox"
                                            className='form-check-input'
                                            id={`checkbox-${stream.streamId}`}
                                            checked={selectedValues.includes(stream.streamName)}
                                            onChange={() => handleSelect(stream.streamName)}
                                        />
                                        {stream.streamName}
                                    </label>
                                </li>
                            ))} 

                        </ul>
                    </div>
                )}
            </div>
            <div className='container-fluid mt-3'>
                <div className='row'>
                {filteredStreams.map((stream) => {
                    return (
                        <div className='col-12 col-sm-12 col-xl-6 col-xxl-6 col-xxxl-6 col-xxxxl-6 mx-0 mb-2 px-1' key={stream.index}>
                            <SummaryCard
                                title={stream.streamName}
                                eventId={selectedEventId}
                                sessionId={selectedSessionId}
                                driverId={stream.streamName}
                                summaries={stream.filteredContent.map(summary => ({
                                    timestamp: summary.Timestamp,
                                    prompt: summary.Prompt,
                                    text: summary.Text,
                                }))}
                                driver_transcriptions = {transcriptsShort[stream.streamName]}
                                showToast={addMessage}
                            />
                        </div>
                    )
                })}
                </div>
            </div>
            <PToast theme="auto" />
        </div>
    )
}