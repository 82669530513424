import React, { useState, useRef, memo, useEffect, useCallback } from "react";
import '../assets/styles/card.css'
import Hls from "hls.js";
import DetailModal from "./detail_modal";
import {
    PButtonPure,
    PText
} from '@porsche-design-system/components-react';
import { useDarkMode } from '../context/DarkModeContext';
import InfiniteScroll from "react-infinite-scroller";

const PAGE_SIZE = 20;

const Card = memo(({ title, url, eventId, sessionId, driverId, isLive, content }) => {
    const cardRef = useRef(null);
    const contentArray = Array.isArray(content) ? content : ["no data"];
    const hasNoData = contentArray.includes('no data');
    const audioRef = useRef(null);
    const hlsRef = useRef(null);
    const audioContextRef = useRef(null);
    const gainNodeRef = useRef(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const { isDarkMode } = useDarkMode();
    const [pageCount, setPageCount] = useState(1);
    const [items, setItems] = useState(contentArray.slice(-PAGE_SIZE));
    const [blockAutoscrollBottom, setBlockAutoscrollBottom] = useState(false);
    const [playInitiated, setPlayInitiated] = useState(false);  
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);

    // unix to hour - minute - second
    const formatTimestamp = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleString('de', options);
    }, []);

    // unix to year - month - day
    const formatDate = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('de', options);
    }, []);

    const handleLabelClick = useCallback((item) => {
        setSelectedLabel(item);
    }, []);
    
    const handleCloseModal = () => {
        setSelectedLabel(null);
    };

    // renders labels with the time and text and renders a modal onClick
    const renderContent = useCallback(
        (items) => (
            <ul className="text-list">
                {items.map((item, i) => (
                    <li key={i}
                        className={item.priority === 1 ? 'prio-high' : 'prio-low'}>
                        <label className="text-row"
                            onClick={() => handleLabelClick(item)}
                        >
                            <span className="timestamp">{formatTimestamp(item.time)}:</span>
                            <span className="transcribed_text">{item.text}</span>
                        </label>
                    </li>
                ))}
            </ul>
        ),
        [handleLabelClick, formatTimestamp]
    );

    useEffect(() => {
        return () => {
            if (hlsRef.current) {
                hlsRef.current.destroy();
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    useEffect(() => {
        if (isPlaying && audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        } else if (audioRef.current) {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    // play stream button handler
    const playStream = useCallback(() => {
        if (!playInitiated && Hls.isSupported() && audioRef.current && url.endsWith('.m3u8')) {
            hlsRef.current = new Hls();
            hlsRef.current.attachMedia(audioRef.current);
            hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
                hlsRef.current.loadSource(url);
            });

            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            gainNodeRef.current = audioContextRef.current.createGain();
            const source = audioContextRef.current.createMediaElementSource(audioRef.current);
            source.connect(gainNodeRef.current).connect(audioContextRef.current.destination);
            gainNodeRef.current.gain.value = volume;

            setPlayInitiated(true);
        }
        setIsPlaying(true);
        console.log('play stream');
    }, [playInitiated, url, volume]);

    // stop stream button handler
    const stopStream = useCallback(() => {
        setIsPlaying(false);
        console.log('stop stream');
    }, []);

    useEffect(() => {
        if (gainNodeRef.current) {
            gainNodeRef.current.gain.value = isMuted ? 0 : volume;
        }
    }, [volume, isMuted]);

    // get the date from the first content item
    const date = contentArray.length > 0 && contentArray[0].time ? formatDate(contentArray[0].time) : "";

    const loadMore = (page) => {
        const newItems = contentArray.slice(-1 * page * PAGE_SIZE);
        setItems(newItems);
        setPageCount(page);
    };

    useEffect(() => {
        const items = contentArray.slice(-1 * pageCount * PAGE_SIZE)
        setItems(items)
    }, [contentArray, pageCount, driverId]);

    useEffect(() => {
            if (cardRef.current !== null) {
                const handleScroll = () => {
                    if (cardRef.current !== null) {
                        if (
                            cardRef.current.scrollTop +
                            cardRef.current.clientHeight -
                            cardRef.current.scrollHeight >
                            -10
                        ) {
                            setBlockAutoscrollBottom(false);
                        }else{
                            setBlockAutoscrollBottom(true);
                        } 
                    }
                };
                // Add Listener
                cardRef.current?.addEventListener("scroll", handleScroll);
                // Remove Listener
                return () =>
                    cardRef.current?.removeEventListener("scroll", handleScroll);
            }
        }, [cardRef.current]);
    
    useEffect(() => {
        const container = cardRef.current;
        if (container && !blockAutoscrollBottom) {
            container.scrollTo({ top: container.scrollHeight });
        }
    }, [items, cardRef.current, blockAutoscrollBottom]);
    
    return (
        <div className={`card 
            ${isDarkMode ? 'text-white bg-dark' : ''}
            ${isPlaying ? 'border-danger' : ''}`}>
            <div className={`card-header ${isDarkMode ? 'border-light' : ''}`}>
                <div>
                    <h5 size="medium">
                        {title} - <span style={{fontWeight:400}}>{date}</span>
                    </h5>
                    <label className={isLive ? 'live' : 'not-live'}> live</label>
                </div>
            </div>

            <div className="card-body" ref={cardRef}>
                {hasNoData ? (
                    <PText>no data</PText>
                ) : (
                    <InfiniteScroll
                    isReverse={true}
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={contentArray.length > pageCount * PAGE_SIZE}
                    useWindow={false}
                    getScrollParent={() => cardRef.current}
                    >
                        {renderContent(items)}
                    </InfiniteScroll>
                )}
                {selectedLabel && (
                    <DetailModal
                        isOpen={!!selectedLabel}
                        onClose={handleCloseModal}
                        name={title}
                        time={formatTimestamp(selectedLabel.time)}
                        text={selectedLabel.text}
                        eventId={eventId}
                        sessionId={sessionId}
                        driverId={driverId}
                        timestamp={selectedLabel.timestamp}
                        priority={selectedLabel.priority}
                    />
                )}
            </div>
            <div className={`card-footer ${isDarkMode ? 'border-light' : ''}`}>
                <div className="btn-toolbar justify-content-between">
                    {isPlaying ? (
                        <PButtonPure icon='pause' onClick={stopStream} 
                        theme={`${isDarkMode ? 'dark' : 'light'}`}/>
                    ) : (
                        <PButtonPure icon='play' onClick={playStream} 
                        theme={`${isDarkMode ? 'dark' : 'light'}`}/>
                    )}
                    <div className="volume-bar">
                        {isMuted ? (
                            <PButtonPure icon="volume-off" onClick={() => setIsMuted(false)} theme={`${isDarkMode ? 'dark' : 'light'}`} disabled={!isLive}
                            />
                        ) : (
                            <PButtonPure icon="volume-up" onClick={() => setIsMuted(true)} theme={`${isDarkMode ? 'dark' : 'light'}`} disabled={!isLive}
                            />
                        )}
                        <input
                            className={`volume-slider-${title}`}
                            type="range"
                            min={0}
                            max={1}
                            step={0.05}
                            value={volume}
                            onChange={e =>{
                                setVolume(e.target.valueAsNumber)
                            }}
                            disabled={!isLive || isMuted}
                        />
                    </div>
                </div>
            </div>
            <audio ref={audioRef} controls preload="none" style={{ display: 'none', visibility: 'hidden' }} />
        </div>
    )
});

export default Card;