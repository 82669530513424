import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEvent } from '../websocket/eventContext';
import { useSocket } from '../websocket/websocket';
import {
  PFlyoutMultilevel,
  PFlyoutMultilevelItem,
} from '@porsche-design-system/components-react';
import { useDarkMode } from '../context/DarkModeContext';

const FlyoutMenu = ({ isFlyoutNavigationOpen, onDismiss, onUpdate, flyoutNavigationActiveIdentifier }) => {
  const { setSelectedEventId } = useEvent();
  const { eventList } = useSocket();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();

  useEffect(() => {
    if (eventList) {
      const sortedEvents = eventList.sort((a, b) => b.name.localeCompare(a.name));
      setEvents(sortedEvents);
    }
  }, [eventList]);

  const handleEventClick = (eventId, path) => {
    console.log('Setting selected event ID:', eventId);
    setSelectedEventId(eventId);
    navigate(path);
  };

  const handleLogout = () => {
    //link to logout page
  };

  return (
    <div className="grid-container">
      <PFlyoutMultilevel
        open={isFlyoutNavigationOpen}
        activeIdentifier={flyoutNavigationActiveIdentifier}
        position="start"
        theme={isDarkMode ? 'dark' : 'light'}
        onDismiss={onDismiss}
        onUpdate={onUpdate}
      >
        <PFlyoutMultilevelItem identifier="audio" label="Audio Streams">
          {events.map(({ id, name }) => (
            <a key={id} href="" onClick={() => handleEventClick(id, '/audio')}>
              {name}
            </a>
          ))}
        </PFlyoutMultilevelItem>
        <PFlyoutMultilevelItem identifier='session-summaries' label='Session Summaries'>
          {events.map(({ id, name }) => (
            <a key={id} href="" onClick={() => handleEventClick(id, '/session-summaries')}>
              {name}
            </a>
          ))}        </PFlyoutMultilevelItem>
        <PFlyoutMultilevelItem identifier="admin" label="Admin">
          <a href="/plan-stream">Plan Streams</a>
          <a href="/streams">Streams Dashboard</a>
        </PFlyoutMultilevelItem>
        {/* <PLinkPure identifier="logout" onClick={handleLogout}>
          Logout
        </PLinkPure> */}
      </PFlyoutMultilevel>
    </div>
  );
};

export default FlyoutMenu;